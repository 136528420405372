/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  return (
    <div className="bio">
      <StaticImage
        className="bio__avatar"
        layout="constrained"
        formats={["auto", "webp", "avif"]}
        src="../images/profile-pic.png"
        width={275}
        height={275}
        quality={100}
        placeholder="blurred"
        alt="Photo of Ryan Hyslop"
      />
      <h3>
        I'm a contract lead developer and proud generalist with hands on
        experience in the <em>entire end to end delivery</em> of digital
        products; from discovery and user research through to client side and
        backend build.
      </h3>

      <div>
        <h5>Think I can help you?</h5>
        <a href="mailto:hello@ryanhyslop.uk" className="btn">
          Get in touch <span className="btn__emoj">✉️</span>
        </a>
      </div>
    </div>
  )
}

export default Bio
